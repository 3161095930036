
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html, body { 
  height: 100%;
  min-height: 100%; 
}

.main-content {
    min-height: 700px;
    height: auto !important;
    height: 100%;
    margin: 0 auto 38px; 
}

.footer, .push {
    height: 100px; 
    min-height: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3{
  padding-left: 10px;
}

.alertify .ajs-footer .ajs-button {
  padding: 12px 24px;
  font-size: 16px;
  white-space: initial;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  color: #19191a;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  line-height: 1.5;
  border-radius: 4px;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.alertify .ajs-button.ajs-ok{
  color: #fff;
  background-color: #06c;
  border-color: #06c;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
}
.alertify .ajs-header {
  border-bottom: 1px solid #ffffff;
  color: transparent;
  padding: 0 15px 15px;
}

.alertify .ajs-footer {
  border-top: 1px solid #ffffff;
  padding: 10px 15px 10px;
}

.alertify .ajs-commands {
  position: relative;
  right: 4px;
  margin: -24px -15px 0 0;
  z-index: 2;
  float: right;
}

.alertify .ajs-commands .ajs-pin,
.alertify .ajs-commands .ajs-maximize{
  display:none;
}

.alertify-notifier .ajs-message {
  color: #818182;
  background-color: #fefefe;
  border: solid 1px #fdfdfe;
  border-radius: .25rem;
  text-shadow: none;
  text-align: left;
}
.alertify-notifier .ajs-message.ajs-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  text-shadow: none;
}
.alertify-notifier .ajs-message.ajs-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-shadow: none;
}
.alertify-notifier .ajs-message.ajs-warning, .alert-warning {
  color: #856404;
  background-color: #ffeeb9;
  border-color: #ffd34e;
  text-shadow: none;
}

/* FULL PAGE MODAL */
.modal.show .modal-dialog.modal-fullpage{
  margin: 0;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}

.modal.show .modal-dialog.modal-fullpage .modal-content{
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
  background: white;
}

.modal.show .modal-dialog.modal-fullpage .modal-content .modal-header {
  padding: 24px 24px 24px;
}

.modal.show .modal-dialog.modal-fullpage .modal-content .modal-header .close
{
  color: #000;
  text-shadow: none;
  opacity: 1;
  font-size: 24px;
  box-shadow: none;
}

.modal.show .modal-dialog.modal-fullpage .modal-content .modal-header .close:hover,
.modal.show .modal-dialog.modal-fullpage .modal-content .modal-header .close:focus{
  background: rgba(0, 0, 0, 0.2);
}

.modal.show .modal-fullpage .modal-body{
  height: calc(100% - 80px);
  max-height: calc(100% - 80px);
  overflow: auto;
  padding: 0 24px 0;
}

.modal.show .modal-fullpage .attachment-figure
{
  background-color: white;
  min-height: 60vh;
  min-width: 300px;
  width: 90vh;
  max-width: 100%;
  max-height: 70vh;
  height: 100%;
}

.modal.show .modal-fullpage .pdf-canvas canvas{
  max-height: 80vh;
}

.sicondir-label{ 
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  color: rgb(65, 100, 134);
  cursor: text;
  display: block;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  margin-bottom: 8px;
  max-width: 100%;
  overflow-wrap: break-word;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
  text-align: left;
  text-overflow: clip;
  top: 0px;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease-out;
  white-space: normal;
  /*width: 384.85px;*/
  z-index: auto
}

.sicondir-error{
  background-color: rgb(255, 255, 255);
  background-image: none;
  background-position-x: 12.8px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  border-bottom-color: rgb(217, 54, 79);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(217, 54, 79);
  border-left-style: solid;
  border-left-width: 6px;
  border-right-color: rgb(217, 54, 79);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgb(217, 54, 79);
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: rgb(34, 34, 34);
  display: block;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 12.8px;
  font-weight: 400;
  line-height: 18.2833px;
  margin-bottom: 0px;
  margin-left: 0px;
  overflow-wrap: break-word;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  position: relative;
  text-align: left;
  white-space: normal;
  word-break: break-word;
}

.sicondir-textarea{
border-bottom-color: rgb(23, 50, 77);
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
border-bottom-style: solid;
border-bottom-width: 1px;
border-image-outset: 0;
border-image-repeat: stretch;
border-image-slice: 100%;
border-image-source: none;
border-image-width: 1;
border-left-color: rgb(23, 50, 77);
border-left-style: solid;
border-left-width: 1px;
border-right-color: rgb(23, 50, 77);
border-right-style: solid;
border-right-width: 1px;
border-top-color: rgb(23, 50, 77);
border-top-left-radius: 0px;
border-top-right-radius: 0px;
border-top-style: solid;
border-top-width: 1px;
box-shadow: none;
box-sizing: border-box;
color: rgb(23, 50, 77);
font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
font-size: 18px;
font-weight: 700;
height: 114.45px;
line-height: 27.9833px;
margin-bottom: 0px;
margin-left: 0px;
margin-right: 0px;
margin-top: 0px;
outline-color: rgb(23, 50, 77);
outline-style: none;
outline-width: 0px;
overflow-x: auto;
overflow-y: auto;
padding-bottom: 6.75px;
padding-left: 9px;
padding-right: 9px;
padding-top: 6.75px;
resize: vertical;
text-align: start;
transition-delay: 0s;
transition-duration: 0.2s;
transition-property: all;
transition-timing-function: ease-out;
}