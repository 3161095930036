.react-select-container{
    border-radius: 0px !important;
    border-bottom: 1px solid #d0d0d0;
  }
  
  .react-select-container.react-select--is-disabled{
    border-bottom: none;
  }
  
  .react-select__control{
    min-height: unset !important;
    height: 100%;
    border: 1px solid white !important;
    border-radius: 0 !important;
  }
  
  .uk-form-danger .react-select__control, .uk-form-danger:focus .react-select__control{
    color: inherit!important;
    border: 1px solid #f0506e !important;
    border-color: white !important;
    border-radius: 0 !important;
    /* background: transparent!important; */
  }
  
  .react-select__value-container{
    padding: 0 10px !important;
    height: 100%;
    font-weight: bold;
  }
  
  .react-select__indicators{
    height:46px;
  }
  
  .react-select__dropdown-indicator{
    color: #000 !important;
  }
  
  .has-success .react-select__control {
    border-color: #44b058 !important;
  }
  
  .has-error .react-select__control {
    border-color: #ec3537 !important;
  }
  
  .react-select-container{
    /* z-index: 4; */
    position:relative;
  }
  
  .react-select__control--is-disabled{
    background-color: transparent !important;
      color: #d0d0d0;
      border-style: dashed !important;
      border-color: #d0d0d0 !important;
  }
  
  .react-select__single-value.react-select__single-value--is-disabled{
    color: hsl(0, 0%, 50%);
    opacity: 1;
  }
  